<template>
  <div>
    <v-row>
      <v-col class="d-inline-flex">
        {{ $t('storage.consearch') }}
        <v-menu
          v-model="mdte1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          width="100px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dte1"
              :label="$t('all.from')"
              prepend-icon="fa-calendar"
              readonly
              dense
              hide-details
              v-on="on"
              @blur="date1 = parseDate(dte1)"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date1" @input="mdte1 = false" locale="ru-ru"></v-date-picker>
        </v-menu>

        <v-menu
          v-model="mdte2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dte2"
              :label="$t('all.to')"
              prepend-icon="fa-calendar"
              readonly
              v-on="on"
              dense
              hide-details
              @blur="date2 = parseDate(dte2)"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date2" @input="mdte2 = false" locale="ru-ru"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="text-right">
        <v-btn small color="primary" @click="dataLoad">
          {{ $t('all.find') }}
        </v-btn>
        <v-btn small color="success" @click="addRow"> <v-icon dark>fa-plus</v-icon> {{ $t('all.add') }} </v-btn>
      </v-col>
    </v-row>

    <v-simple-table dense>
      <thead>
        <tr>
          <th class="text-left">{{ $t('all.num') }}</th>
          <th class="text-left">{{ $t('all.date') }}</th>
          <th class="text-left">{{ $t('storage.conprovider') }}</th>
          <th class="text-left">{{ $t('all.storage') }}</th>
          <th class="text-left">{{ $t('all.user') }}</th>
          <th class="text-left">{{ $t('all.cost') }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="item in rows">
          <tr v-bind:key="item.id" @click="editRow(item)" style="cursor: pointer">
            <td>{{ item.num }}</td>
            <td>{{ item.dte }}</td>
            <td>{{ item.provider_name }}</td>
            <td>{{ item.storage_name }}</td>
            <td>{{ item.user_fio }}</td>
            <td>{{ item.cost }}</td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>

    <consignment_edit
      v-if="rowEdit"
      v-bind:title="modalTitle"
      v-bind:view="modalView"
      v-on:close="modalClose"
      v-on:delete="deleteRow"
    ></consignment_edit>
  </div>
</template>

<script>
import consignment_edit from './consignment_edit.vue'

export default {
  props: [],
  components: {
    consignment_edit,
  },
  data() {
    return {
      mdte1: false,
      mdte2: false,
      date1: null,
      date2: null,
      tableLoad: false,
      rows: [],
      rowEdit: false,
      groupDel: false,
      modalTitle: '',
      modalView: '',
    }
  },
  computed: {
    groups: function() {
      return this.$store.state.spr.goodGroup
    },
    dte1: function() {
      return this.formatDate(this.date1)
    },
    dte2: function() {
      return this.formatDate(this.date2)
    },
  },
  mounted: function() {
    var dt = new Date()
    var month = dt.getMonth() + 1
    var day = dt.getDate()
    var year = dt.getFullYear()
    month = month < 10 ? '0' + month : month
    day = day < 10 ? '0' + day : day
    //                    this.dte2 = day + '.' + month + '.' + year;
    //                    this.dte1 = '01.' + month + '.' + year;
    this.date1 = year + '-' + month + '-' + '01'
    this.date2 = year + '-' + month + '-' + day

    this.dataLoad()
  },
  methods: {
    dteUpd1(v) {
      this.dte1 = v
    },
    dteUpd2(v) {
      this.dte2 = v
    },
    dataLoad: function() {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'good/consignment', {
          dte1: t.dte1,
          dte2: t.dte2,
        })
        .then(
          response => {
            if (response.body.err > 0) {
              t.rows = ['error']
            } else {
              t.rows = response.body
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    addRow: function() {
      this.rowEdit = true
      this.modalView = []
    },
    editRow: function(view) {
      this.rowEdit = true
      this.modalView = view
    },
    modalClose: function(prm) {
      this.rowEdit = ''
      if (prm == 'reload') {
        this.dataLoad()
      }
    },
    deleteRow: function() {
      //            this.groupDel=true;
      //            this.modalView=view;
    },
    formatDate(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate(date) {
      if (!date) {
        return null
      }
      const [dte] = date.split(' ')
      const [day, month, year] = dte.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
}
</script>

<style></style>
