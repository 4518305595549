<template>
  <div>
    <v-row>
      <v-col>
        <h3>{{ title }}</h3>
      </v-col>
      <v-col class="text-right">
        <v-btn
          outlined
          small
          color="primary"
          @click="
            showTab = 'sgood'
            title = $t('storage.sgoodtitle')
          "
          v-if="showTab != 'sgood'"
          >{{ $t('storage.sgoodtitle') }}</v-btn
        >
        <v-btn
          outlined
          small
          color="primary"
          @click="
            showTab = 'consignment'
            title = $t('storage.consignmenttitle')
          "
          v-if="showTab != 'consignment'"
          >{{ $t('storage.consignmenttitle') }}</v-btn
        >
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn outlined small color="primary" v-on="on">
              {{ $t('storage.catalog') }}
              <v-icon class="hidden-sm-and-down">fa-caret-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="
                showTab = 'group'
                title = $t('storage.grouptitle')
              "
            >
              <v-list-item-title>
                {{ $t('storage.grouptitle') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                showTab = 'good'
                title = $t('storage.goodtitle')
              "
            >
              <v-list-item-title>
                {{ $t('storage.goodtitle') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                showTab = 'storage'
                title = $t('storage.storagetitle')
              "
            >
              <v-list-item-title>
                {{ $t('storage.storagetitle') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                showTab = 'provider'
                title = $t('storage.providertitle')
              "
            >
              <v-list-item-title>
                {{ $t('storage.providertitle') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <br />
    <v-divider></v-divider> <br />
    <modal_sgood v-if="showTab == 'sgood'"> </modal_sgood>
    <storage v-if="showTab == 'storage'"> </storage>
    <group v-if="showTab == 'group'"> </group>
    <good v-if="showTab == 'good'"> </good>
    <provider v-if="showTab == 'provider'"> </provider>
    <consignment v-if="showTab == 'consignment'"> </consignment>
  </div>
</template>

<script>
import group from './group.vue'
import good from './price_good.vue'
import modal_sgood from './sgood.vue'
import storage from './storage.vue'
import provider from './provider.vue'
import consignment from './consignment.vue'

export default {
  data() {
    return {
      showTab: 'sgood',
      title: this.$t('storage.sgoodtitle'),
    }
  },
  watch: {},
  components: {
    group,
    modal_sgood,
    storage,
    good,
    provider,
    consignment,
  },
  computed: {},
  mounted: function() {
    //        this.$store.dispatch('loadStatus');
    //        this.$store.dispatch('loadSetting');
  },
  methods: {},
}
</script>

<style></style>
