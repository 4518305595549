<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          {{ $t('storage.conedittitle') }} <v-spacer></v-spacer> {{ $t('storage.conpositions') }}:
          <b>{{ selGoodStat.count }}</b
          >&nbsp;&nbsp;&nbsp;{{ $t('all.cost') }}:<b>{{ selGoodStat.smm }}</b>
        </v-card-title>
        <v-card-text>
          <v-card-title>
            <v-select
              v-bind:items="storages"
              v-model="filterStorage"
              :label="$t('all.storage')"
              bottom
              item-text="name"
              item-value="id"
            ></v-select>
            &nbsp;&nbsp;&nbsp;
            <v-select
              v-bind:items="providers"
              v-model="filterProvider"
              :label="$t('storage.conprovider')"
              bottom
              item-text="name"
              item-value="id"
            ></v-select>
            &nbsp;&nbsp;&nbsp;
            <v-text-field
              label="$t('all.find')"
              v-model="search"
              append-icon="magnify"
              @keyup="getGood()"
            ></v-text-field>
            &nbsp;&nbsp;&nbsp;
            <v-checkbox v-bind:label="$t('storage.conselshow')" v-model="filterSelected" light></v-checkbox>
          </v-card-title>

          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">{{ $t('storage.goodname') }}</th>
                <th class="text-left">{{ $t('storage.goodbarcode') }}</th>
                <th class="text-left" width="100px">
                  {{ $t('storage.conlastcost') }}
                </th>
                <th class="text-left" width="300px"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="grp in getGroup">
                <tr v-bind:key="grp.id" class="blue lighten-4">
                  <td colspan="4">{{ grp.name }}</td>
                </tr>
                <template v-for="row in getGood(grp.id)">
                  <tr v-bind:key="row.id">
                    <td>{{ row.name }}</td>
                    <td>{{ row.barcode }}</td>
                    <td>
                      <span class="pull-right">{{ row.cost_old }}</span>
                    </td>
                    <td>
                      <v-btn
                        v-if="!row.quantity"
                        @click=";(row.quantity = 1), (row.cost_new = row.cost)"
                        small
                        block
                        color="info"
                      >
                        <v-icon>fa-check</v-icon>
                        {{ $t('storage.accept') }}
                      </v-btn>
                      <v-row row v-if="row.quantity">
                        <v-col xs4>
                          <div style="width: 70px">
                            <v-text-field
                              :label="$t('all.cost')"
                              v-model="row.cost"
                              type="number"
                              hide-details
                              dense
                            ></v-text-field>
                          </div>
                        </v-col>
                        <v-col xs4>
                          <div style="width: 70px">
                            <v-text-field
                              :label="$t('all.count')"
                              v-model="row.quantity"
                              type="number"
                              hide-details
                              dense
                            ></v-text-field>
                          </div>
                        </v-col>
                        <v-col xs4>
                          <b>= {{ row.cost * row.quantity }}</b>
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn outlined @click="$emit('close', 'reload')"> {{ $t('all.close') }} </v-btn>&nbsp;
          <v-btn color="primary" @click.native="save">
            {{ $t('all.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['storage', 'view'],
  components: {},
  data() {
    return {
      dialog: true,
      filterStorage: '',
      filterProvider: '',
      search: '',
      filterSelected: false,
      sgood: [],
    }
  },
  computed: {
    storages: function() {
      return this.$store.state.spr.storage
    },
    providers: function() {
      return this.$store.state.spr.provider
    },
    getGroup: function() {
      var a = {},
        b = []
      this.sgood.forEach(function(e) {
        a[e['group_name']] = e['group']
      })
      for (var key in a) {
        b.push({
          name: key,
          id: a[key],
        })
      }
      return b
    },
    selGoodStat: function() {
      var smm = 0,
        cnt = 0
      this.sgood.forEach(function(e) {
        if (e['quantity'] > 0) {
          smm += e['quantity'] * e['cost']
          cnt++
        }
      })
      return {
        smm: smm,
        count: cnt,
      }
    },
    getSaveGood: function() {
      var a = {},
        i = 0
      this.sgood.forEach(function(e) {
        if (e['quantity'] > 0) {
          a[i++] = {
            //                        cost:!!e['cost_new']?e['cost_new']:e['cost'],
            cost: e['cost'],
            quantity: e['quantity'],
            id: e['id'],
          }
        }
      })
      return a
    },
  },
  mounted: function() {
    var t = this
    this.filterStorage = this.storage
    if (!this.filterStorage) {
      this.filterStorage = t.storages[0]['id']
    }
    this.$store.dispatch('loadProvider').then(() => {
      if (t.view.provider > 0) {
        t.filterProvider = t.view.provider
      } else {
        t.filterProvider = t.providers[0]['id']
      }
    })
    this.dataLoad()
  },
  methods: {
    dataLoad: function() {
      var t = this
      console.log()
      this.$http
        .post(this.$store.state.apiUrl + 'good/consignmentGood', {
          consignment: t.view.id,
        })
        .then(
          response => {
            if (response.body.err > 0) {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: t.$t(response.body.msg),
              })
            } else {
              t.sgood = response.body
              this.$emit('update', response.body)
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    getGood: function(e) {
      var t = this

      var b = this.sgood.filter(function(item) {
        return item['group'] == e &&
          (t.filterSelected == false || (t.filterSelected == true && item['quantity'] > 0)) &&
          (t.search == '' ||
            t.search == item['barcode'] ||
            item['name'].toUpperCase().indexOf(t.search.toUpperCase()) >= 0)
          ? item
          : false
      })
      return b
    },
    save: function() {
      var t = this

      //            var arr =Object.assign({}, this.view);
      var arr = {}
      arr.provider = this.filterProvider
      arr.storage = this.filterStorage
      arr.id = t.view.id

      this.$http
        .post(this.$store.state.apiUrl + 'good/consignmentSave/', {
          //                id: this.irule,
          prm: arr,
          good: t.getSaveGood,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: t.$t(response.body.msg),
            })
            if (!response.body.err) {
              arr.id = response.body.id
              this.$emit('close', 'reload', arr)
            }
          },
          err => {
            console.log(err)
          },
        )
    },
  },
}
</script>

<style></style>
