<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title class="headline" v-if="view.id">
        {{ $t('storage.goodedittitle') }}
      </v-card-title>
      <v-card-title class="headline" v-if="!view.id">
        {{ $t('storage.goodaddtitle') }}
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" lazy-validation>
          <v-row>
            <v-col xs12 sm6>
              <v-text-field
                v-model="view.name"
                :label="$t('storage.goodname')"
                required
                :rules="[(v) => !!v || $t('all.fieldnoempty')]"
              ></v-text-field>
            </v-col>
            <v-col xs12 sm6>
              <v-select
                v-bind:items="groups"
                v-model="grp"
                :label="$t('storage.goodgroup')"
                bottom
                item-text="name"
                item-value="id"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6>
              <v-text-field
                v-model="view.cost"
                :label="$t('storage.goodcost')"
                type="number"
                required
                :rules="[(v) => !!v || $t('all.fieldnoempty')]"
              ></v-text-field>
            </v-col>
            <v-col xs12 sm6>
              <v-text-field
                v-model="view.barcode"
                :label="$t('storage.goodbarcode')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" v-if="btnDel" @click="del">
          {{ $t('all.delete') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click.native="$emit('close')"> {{ $t('all.close') }} </v-btn>
        <v-btn color="primary" dark @click.native="save">
          {{ $t('all.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import confirm from './../../components/confirm.vue'
import { create } from 'vue-modal-dialogs'
const confirmBox = create(confirm)
export default {
  props: ['title', 'view', 'groupDef'],
  data() {
    return {
      dialog: true,
      btnDel: false,
      valid: false,
      grp: '',
    }
  },
  computed: {
    selgroup: function () {
      return this.view.id ? this.view.group : this.groupDef
    },
    groups: function () {
      return this.$store.state.spr.goodGroup
    },
  },
  mounted: function () {
    var t = this
    this.btnDel = this.view.id ? true : false
    t.grp = t.selgroup
    this.$store.dispatch('loadGoodGroup')
  },
  methods: {
    del: function () {
      var t = this
      confirmBox({
        text: t.$t('storage.gooddelconfirm', [t.view.name]),
        type: 'delete',
        title: t.$t('storage.gooddeltitle'),
      })
        .transition()
        .then((response) => {
          if (response) {
            t.$store.commit('loadingStartShow', true)
            this.$http
              .post(this.$store.state.apiUrl + 'good/goodDelete/', {
                id: t.view.id,
              })
              .then(
                (response) => {
                  t.$store.commit('loadingStartShow', false)
                  t.$store.dispatch('setMessage', {
                    type: response.body.err == 1 ? 'error' : 'success',
                    message: t.$t(response.body.msg),
                  })
                  if (!response.body.err) {
                    t.$emit('close', 'reload')
                  }
                },
                (err) => {
                  console.log(err)
                }
              )
          }
        })
    },
    save: function () {
      var t = this
      this.view.group = this.grp

      var arr = Object.assign({}, this.view)

      if (t.valid) {
        t.$store.commit('loadingStartShow', true)
        this.$http
          .post(this.$store.state.apiUrl + 'good/save/', {
            good: arr,
          })
          .then(
            (response) => {
              t.$store.commit('loadingStartShow', false)
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: t.$t(response.body.msg),
              })
              if (!response.body.err) {
                this.$emit('close', 'reload')
              }
            },
            (err) => {
              console.log(err)
            }
          )
      }
    },
  },
}
</script>

<style></style>
