<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          {{ $t('storage.trashtitle') }} <v-spacer></v-spacer> {{ $t('storage.conpositions') }}:
          <b>{{ selGoodStat.count }}</b
          >&nbsp;&nbsp;&nbsp;{{ $t('all.cost') }}:<b>{{ selGoodStat.smm }}</b>
        </v-card-title>
        <v-divider></v-divider>
        <v-card>
          <v-card-title>
            <v-select
              v-bind:items="storages"
              v-model="filterStorage"
              :label="$t('all.storage')"
              bottom
              item-text="name"
              item-value="id"
            ></v-select>
            &nbsp;&nbsp;&nbsp;
            <v-text-field :label="$t('all.find')" v-model="search" append-icon="fa-search"></v-text-field>
            &nbsp;&nbsp;&nbsp;
            <v-checkbox v-bind:label="$t('storage.conselshow')" v-model="filterSelected" light></v-checkbox>
          </v-card-title>
          <v-simple-table dense>
            <thead>
              <tr>
                <th class="text-left">{{ $t('storage.goodname') }}</th>
                <th class="text-left">{{ $t('storage.goodbarcode') }}</th>
                <th class="text-left" width="100px">{{ $t('all.cost') }}</th>
                <th class="text-left" width="100px">{{ $t('all.count') }}</th>
                <th class="text-left" width="100px"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="grp in getGroup">
                <tr v-bind:key="grp.id" class="blue lighten-4">
                  <td colspan="5">{{ grp.name }}</td>
                </tr>
                <template v-for="row in getGood(grp.id)">
                  <tr v-bind:key="row.id">
                    <td>{{ row.name }}</td>
                    <td>{{ row.barcode }}</td>
                    <td>{{ row.cost }}</td>
                    <td>
                      <span class="pull-right">{{ row.quantity }}</span>
                    </td>
                    <td>
                      <v-btn v-if="!row.trash" @click="showTrash(row)" small color="info">
                        <v-icon>fa-trash-o</v-icon>
                        {{ $t('storage.trash') }}
                      </v-btn>
                      <v-text-field
                        v-if="row.trash"
                        :label="$t('all.count')"
                        v-model="row.trash"
                        type="number"
                        @change="checkRow(row)"
                      ></v-text-field>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </v-simple-table>
        </v-card>
        <v-divider></v-divider>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn outlined @click="$emit('close', 'reload')"> {{ $t('all.close') }} </v-btn>&nbsp;
          <v-btn color="primary" v-show="selGoodStat.count > 0" @click.native="save">
            {{ $t('all.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['storage'],
  components: {},
  data() {
    return {
      dialog: true,
      filterStorage: '',
      search: '',
      filterSelected: false,
      sgood: [],
    }
  },
  watch: {
    filterStorage() {
      this.dataLoad()
    },
  },
  computed: {
    storages: function() {
      return this.$store.state.spr.storage
    },
    getGroup: function() {
      var a = {},
        b = []
      this.sgood.forEach(function(e) {
        a[e['group_name']] = e['group']
      })
      for (var key in a) {
        b.push({ name: key, id: a[key] })
      }
      return b
    },
    selGoodStat: function() {
      var smm = 0,
        cnt = 0
      this.sgood.forEach(function(e) {
        if (e['trash'] > 0) {
          smm += e['trash'] * e['base_cost']
          cnt++
        }
      })
      return { smm: smm, count: cnt }
    },
    getSaveGood: function() {
      var a = {},
        i = 0
      this.sgood.forEach(function(e) {
        if (e['trash'] > 0) {
          a[i++] = {
            quantity: e['trash'],
            id: e['good'],
          }
        }
      })
      return a
    },
  },
  mounted: function() {
    this.filterStorage = this.storage
    this.dataLoad()
  },
  methods: {
    dataLoad: function() {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'good/sgood', {
          storage: t.filterStorage,
        })
        .then(
          response => {
            if (response.body.err > 0) {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: response.body.msg,
              })
            } else {
              t.sgood = response.body
              this.$emit('update', response.body)
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    getGood: function(e) {
      var t = this
      var b = this.sgood.filter(function(item) {
        return item['group'] == e &&
          (t.filterSelected == false || (t.filterSelected == true && item['trash'] > 0)) &&
          (t.search == '' ||
            t.search == item['barcode'] ||
            item['name'].toUpperCase().indexOf(t.search.toUpperCase()) >= 0)
          ? item
          : false
      })
      return b
    },
    save: function() {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'good/trashSave/', {
          storage: this.filterStorage,
          good: t.getSaveGood,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: t.$t(response.body.msg),
            })
            if (!response.body.err) {
              this.$emit('close', 'reload')
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    showTrash: function(row) {
      this.$set(row, 'trash', 1)
    },
    checkRow: function(row) {
      if (row.trash > row.quantity) {
        this.$set(row, 'trash', row.quantity)
      }
    },
  },
}
</script>

<style></style>
