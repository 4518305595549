<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title class="headline">
        {{ $t('storage.historytitle') }}
      </v-card-title>
      <v-card-text>
        <v-simple-table dense>
          <thead>
            <tr>
              <th class="text-left">{{ $t('all.date') }}</th>
              <th class="text-left">{{ $t('all.storage') }}</th>
              <th class="text-left" width="100px">{{ $t('all.count') }}</th>
              <th class="text-left" width="100px">{{ $t('all.cost') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="row in dataHistory">
              <tr v-bind:key="row.id">
                <td>{{ row.dte }}</td>
                <td>{{ row.storage_name }}</td>
                <td>
                  <span class="pull-right">{{ row.cost }}</span>
                </td>
                <td>
                  <span class="pull-right">{{ row.quantity }}</span>
                </td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="$emit('close')"> {{ $t('all.close') }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['good'],
  data() {
    return {
      dialog: true,
      dataHistory: [],
    }
  },
  computed: {},
  mounted: function () {
    var t = this
    t.dataLoad()
  },
  methods: {
    dataLoad: function () {
      var t = this
      //            console.log('loadstorages',t.filterStorage)
      this.$http
        .post(this.$store.state.apiUrl + 'good/history', {
          good: t.good,
        })
        .then(
          (response) => {
            if (response.body.err > 0) {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: t.$t(response.body.msg),
              })
            } else {
              t.dataHistory = response.body
              this.$emit('update', response.body)
            }
          },
          (err) => {
            console.log(err)
          }
        )
    },
  },
}
</script>

<style></style>
