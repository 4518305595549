<template>
  <div>
    <v-row>
      <v-col
        ><v-select
          v-bind:items="groups"
          v-model="filterGroup"
          :label="$t('storage.goodgroup')"
          bottom
          item-text="name"
          item-value="id"
          dense
          hide-details=""
        ></v-select>
      </v-col>
      <v-col class="text-right">
        <v-btn color="success" @click="addGood" small> <v-icon>fa-plus</v-icon> {{ $t('all.add') }} </v-btn>
      </v-col>
    </v-row>

    <br />

    <v-simple-table dense>
      <thead>
        <tr>
          <th class="text-left">{{ $t('storage.goodname') }}</th>
          <th class="text-left">{{ $t('storage.goodbarcode') }}</th>
          <th class="text-left">{{ $t('storage.goodcost') }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="item in tableData">
          <tr @click="editGood(item)" v-bind:key="item.id" style="cursor: pointer">
            <td>{{ item.name }}</td>
            <td>{{ item.barcode }}</td>
            <td>{{ item.cost }}</td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>

    <modal_edit
      v-if="goodEdit"
      v-bind:title="modalTitle"
      v-bind:view="modalView"
      v-bind:groupDef="filterGroup"
      v-on:close="modalClose"
    ></modal_edit>
  </div>
</template>

<script>
import modal_edit from './price_good_edit.vue'

export default {
  data() {
    return {
      filterGroup: '',
      tableData: ['load'],
      modalGroup: '',
      goodEdit: false,
      modalTitle: '',
      modalView: [],
    }
  },
  watch: {
    filterGroup: function(val, oldVal) {
      if (oldVal != '') {
        this.getPrice()
      }
    },
  },
  components: {
    modal_edit,
  },
  computed: {
    groups: function() {
      return this.$store.state.spr.goodGroup
    },
  },
  mounted: function() {
    this.$store.dispatch('loadGoodGroup').then(() => {
      this.getPrice()
    })
  },
  methods: {
    getPrice: function() {
      var t = this
      if (!t.filterGroup) {
        t.filterGroup = t.groups[0]['id']
      }
      this.$http
        .post(this.$store.state.apiUrl + 'good/price', {
          group: t.filterGroup,
        })
        .then(
          response => {
            if (response.body.err > 0) {
              t.tableData = ['error']
            } else {
              t.tableData = response.body
              this.showPrice = true
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    modalClose: function(prm) {
      this.goodEdit = ''
      if (prm == 'reload') {
        this.getPrice()
      }
    },
    addGood: function() {
      this.goodEdit = true
      this.modalView = []
    },
    editGood: function(view) {
      this.goodEdit = true
      this.modalView = view
    },
  },
}
</script>

<style></style>
