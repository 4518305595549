<template>
  <div>
    <v-row>
      <v-col class="d-inline-flex">
        <v-select
          v-bind:items="storages"
          v-model="filterStorage"
          :label="$t('all.storage')"
          bottom
          item-text="name"
          item-value="id"
        ></v-select
        >&nbsp;&nbsp;
        <v-text-field :label="$t('all.find')" v-model="search" append-icon="fa-search"></v-text-field>
      </v-col>
      <v-col class="text-right">
        <v-btn small color="success" @click="showConsignment = true">
          <v-icon>fa-plus</v-icon> {{ $t('storage.buy') }}
        </v-btn>
        <v-btn small color="info" @click="showMove = true">
          {{ $t('storage.move') }}
        </v-btn>
        <v-btn small color="info" @click="showTrash = true">
          {{ $t('storage.trash') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-simple-table dense>
      <thead>
        <tr>
          <th class="text-left">{{ $t('storage.goodname') }}</th>
          <th class="text-left" width="100px">{{ $t('storage.goodcost') }}</th>
          <th class="text-left" width="100px">{{ $t('all.count') }}</th>
          <th width="10px"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="grp in getGroup">
          <tr v-bind:key="grp.id" class="blue lighten-4">
            <td colspan="4">{{ grp.name }}</td>
          </tr>
          <template v-for="row in getGood(grp.id)">
            <tr v-bind:key="row.id">
              <td>{{ row.name }}</td>
              <td>
                <span class="pull-right">{{ row.cost }}</span>
              </td>
              <td>
                <span class="pull-right">{{ row.quantity }}</span>
              </td>
              <td>
                <v-btn x-small outlined color="indigo" @click="history(row.good)"
                  ><v-icon small>fa-history</v-icon></v-btn
                >
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </v-simple-table>

    <consignment
      v-if="showConsignment"
      v-bind:storage="filterStorage"
      v-bind:view="[]"
      v-on:close="
        showConsignment = false
        dataLoad()
      "
    >
    </consignment>
    <trash
      v-if="showTrash"
      v-bind:storage="filterStorage"
      v-on:close="
        showTrash = false
        dataLoad()
      "
    >
    </trash>
    <move
      v-if="showMove"
      v-bind:storage="filterStorage"
      v-on:close="
        showMove = false
        dataLoad()
      "
    >
    </move>
    <hst v-if="showHistory" v-bind:good="filterGood" v-on:close="showHistory = false"> </hst>
  </div>
</template>

<script>
import consignment from './consignment_edit.vue'
import trash from './trash.vue'
import move from './move.vue'
import hst from './history.vue'

export default {
  props: [],
  components: {
    consignment,
    trash,
    move,
    hst,
  },
  data() {
    return {
      filterStorage: '',
      filterGood: '',
      showConsignment: false,
      showTrash: false,
      showMove: false,
      showHistory: false,
      search: '',
      sgood: [],
    }
  },
  watch: {
    filterStorage() {
      this.dataLoad()
    },
  },
  computed: {
    storages: function() {
      return this.$store.state.spr.storage
    },
    getGroup: function() {
      var a = {},
        b = []
      this.sgood.forEach(function(e) {
        a[e['group_name']] = e['group']
      })
      for (var key in a) {
        b.push({ name: key, id: a[key] })
      }
      return b
    },
  },
  mounted: function() {
    var t = this
    this.$store.dispatch('loadStorage').then(() => {
      if (!t.filterStorage) {
        t.filterStorage = t.storages[0]['id']
        //                console.log('storages',t.storages)
        //                console.log('storage',t.storages[0]['id'])
        t.dataLoad()
      }
    })
  },
  methods: {
    dataLoad: function() {
      var t = this
      //            console.log('loadstorages',t.filterStorage)
      this.$http
        .post(this.$store.state.apiUrl + 'good/sgood', {
          storage: t.filterStorage,
        })
        .then(
          response => {
            if (response.body.err > 0) {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: response.body.msg,
              })
            } else {
              t.sgood = response.body
              this.$emit('update', response.body)
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    getGood: function(e) {
      var t = this

      var b = this.sgood.filter(function(item) {
        return item['group'] == e &&
          (t.search == '' ||
            t.search == item['barcode'] ||
            item['name'].toUpperCase().indexOf(t.search.toUpperCase()) >= 0)
          ? item
          : false
      })
      return b
    },
    history: function(e) {
      var t = this
      t.showHistory = true
      t.filterGood = e
    },
  },
}
</script>

<style></style>
