var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('h3',[_vm._v(_vm._s(_vm.title))])]),_c('v-col',{staticClass:"text-right"},[(_vm.showTab != 'sgood')?_c('v-btn',{attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){_vm.showTab = 'sgood'
          _vm.title = _vm.$t('storage.sgoodtitle')}}},[_vm._v(_vm._s(_vm.$t('storage.sgoodtitle')))]):_vm._e(),(_vm.showTab != 'consignment')?_c('v-btn',{attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){_vm.showTab = 'consignment'
          _vm.title = _vm.$t('storage.consignmenttitle')}}},[_vm._v(_vm._s(_vm.$t('storage.consignmenttitle')))]):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","small":"","color":"primary"}},on),[_vm._v(" "+_vm._s(_vm.$t('storage.catalog'))+" "),_c('v-icon',{staticClass:"hidden-sm-and-down"},[_vm._v("fa-caret-down")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.showTab = 'group'
              _vm.title = _vm.$t('storage.grouptitle')}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('storage.grouptitle'))+" ")])],1),_c('v-list-item',{on:{"click":function($event){_vm.showTab = 'good'
              _vm.title = _vm.$t('storage.goodtitle')}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('storage.goodtitle'))+" ")])],1),_c('v-list-item',{on:{"click":function($event){_vm.showTab = 'storage'
              _vm.title = _vm.$t('storage.storagetitle')}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('storage.storagetitle'))+" ")])],1),_c('v-list-item',{on:{"click":function($event){_vm.showTab = 'provider'
              _vm.title = _vm.$t('storage.providertitle')}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('storage.providertitle'))+" ")])],1)],1)],1)],1)],1),_c('br'),_c('v-divider'),_vm._v(" "),_c('br'),(_vm.showTab == 'sgood')?_c('modal_sgood'):_vm._e(),(_vm.showTab == 'storage')?_c('storage'):_vm._e(),(_vm.showTab == 'group')?_c('group'):_vm._e(),(_vm.showTab == 'good')?_c('good'):_vm._e(),(_vm.showTab == 'provider')?_c('provider'):_vm._e(),(_vm.showTab == 'consignment')?_c('consignment'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }