<template>
  <div>
    <v-btn color="success" @click="edit({})" small> <v-icon>fa-plus</v-icon> {{ $t('all.add') }} </v-btn>
    <br />
    <v-simple-table dense>
      <thead>
        <tr>
          <th class="text-left">{{ $t('storage.providername') }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="item in providers">
          <tr v-bind:key="item.id" @click="edit(item)" style="cursor: pointer">
            <td>{{ item.name }}</td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import edit from './../../components/edit.vue'
import { create } from 'vue-modal-dialogs'
const messageBoxE = create(edit)

export default {
  props: [],
  components: {},
  data() {
    return {}
  },
  computed: {
    providers: function() {
      return this.$store.state.spr.provider
    },
  },
  mounted: function() {
    this.$store.dispatch('loadProvider')
  },
  methods: {
    edit(group) {
      var t = this
      messageBoxE({
        urlSave: 'good/providerSave/',
        urlDel: 'good/providerDelete/',
        id: group.id,
        fields: [
          {
            type: 'input',
            text: t.$t('storage.providername'),
            name: 'name',
            value: group.name,
          },
        ],
        title: t.$t('storage.provideredittitle'),
        titleDel: t.$t('storage.providerdeltitle'),
        delMsg: t.$t('storage.providerdelconfirm', [group.name]),
      })
        .transition()
        .then(response => {
          if (response) {
            t.$store.dispatch('updateProvider')
          }
          console.log('response', response)
        })
    },
  },
}
</script>

<style></style>
